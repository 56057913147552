<template>
    <div style="display: flex;justify-content: space-between">
        <div>
            <el-button type="primary" :disabled="loading" @click="openDialog">选图</el-button>
        </div>
        <div>
          <el-select v-model="form.label" :disabled="loading" @change="changeData">
            <el-option v-for="l in labels" :value="l" :label="`方案${l}`"></el-option>
          </el-select>
          <el-button v-if="has_feature_rookie_apply" type="danger" :disabled="loading" @click="applyRookie" style="margin-left: 5px">应用</el-button>
        </div>
<!--        <div>-->
<!--            <el-select v-model="form.country" :disabled="loading" @change="formatData" placeholder="全球" filterable>-->
<!--                <el-option v-for="c in $root.country_list" :value="c.code"-->
<!--                           :label="c.en_name + ' (' + c.code + ')'"></el-option>-->
<!--            </el-select>-->
<!--        </div>-->
    </div>
    <template v-for="d in data">
        <el-card style="margin-top: 5px" shadow="never" :header="`第${d.day}天`" v-if="d" v-loading="loading">
            <vue-draggable-next v-model="d.images" :animation="300" handle=".handle" @change="resort(d.images)">
                <template v-for="rookie in d.images">
                    <div class="handle" style="display: inline-block;position: relative">
                        <el-image style="margin: 5px; width: 220px"
                                  :src="`${$CDN_URL}/${rookie.thumbnail}`"></el-image>
                        <el-icon @click="remove(rookie)"
                                 style="position: absolute; top: 5px; right: 5px; cursor: pointer">
                            <CircleClose/>
                        </el-icon>
                    </div>
                </template>
            </vue-draggable-next>
        </el-card>
    </template>
    <el-table style="margin-top: 10px" v-loading="loading" :data="rookieFilter">
      <el-table-column label="预览图" prop="thumbnail" width="230">
        <template #default="scope">
          <el-image lazy :src="`${$CDN_URL}/${scope.row.thumbnail}`"
                    style="width: 150px"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="ID" prop="image_id" width="150"></el-table-column>
      <el-table-column label="天数" prop="day" width="180">
        <template #default="scope">
          <el-input-number v-model="scope.row.day" :disabled="loading"  @change="updateRookie(scope.row, true)"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="上线时间" prop="online_date" width="200"></el-table-column>
      <el-table-column label="操作" width="250">
        <template #default="scope">
          <el-button :disabled="loading" type="primary" size="small" @click="$refs.differences.open(getImage(scope.row))">不同点</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <el-form :model="form" label-width="100px" ref="form">
            <el-form-item label="天数" prop="day" :rules="[{required: true, message: '输入天数', type: 'number'}]">
                <el-input-number v-model="form.day" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="图片" prop="image_id" :rules="[{required: true, message: '请选择图片'}]">
                <template v-for="image in image_list">
                    <el-image v-if="form.image_id === image.id" style="width: 160px"
                              :src="`${$CDN_URL}/${image.thumbnail}`"></el-image>
                </template>
            </el-form-item>
        </el-form>
        <el-select v-model="query.series" clearable filterable placeholder="系列" @change="reload" style="width: 120px">
          <el-option v-for="series in this.series_list" :label="series.key" :value="series.key"></el-option>
        </el-select>
        <el-input v-model="query.id" prefix-icon="Search" placeholder="ID" @blur="loadImage" style="margin-left:5px; width: 220px"></el-input>
        <el-table style="margin-top: 10px" v-loading="loading" :data="image_list">
            <el-table-column label="预览图" prop="prefix" width="230">
                <template #default="scope">
                    <el-image lazy :src="`${$CDN_URL}/${scope.row.thumbnail}`"
                              style="width: 150px"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="ID" prop="id" width="150"></el-table-column>
            <el-table-column label="系列" prop="series" width="120">
              <template #default="scope">
                <el-tag :disabled="loading" v-if="scope.row.series" v-for="series in scope.row.series">{{ series }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="状态" prop="status" width="150">
              <template #default="scope">
                <el-select :disabled="loading" style="width: 100px" v-model="scope.row.status"
                           @change="update(scope.row)">
                  <el-option label="通过" value="AUDITED"></el-option>
                  <el-option label="初审中" value="AUDITING"></el-option>
                  <el-option label="审核中" value="REVIEWING"></el-option>
                  <el-option label="不通过" value="REJECTED"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="作者" prop="author" width="100"></el-table-column>
            <el-table-column label="上线时间" prop="online_date" width="200">
              <template #default="scope">
                <el-date-picker style="width: 150px" value-format="YYYY-MM-DD" v-model="scope.row.online_date"
                                @change="update(scope.row)" :clearable="false"></el-date-picker>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="250">
                <template #default="scope">
                    <el-button :disabled="loading" type="primary" size="small" @click="$refs.differences.open(scope.row)">不同点</el-button>
                    <el-button type="primary" @click="addImage(scope.row)" size="small" :disabled="loading || scope.row.added">
                      {{ scope.row.added ? '已添加' : '添加' }} </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :total="total" :page-size="this.query.limit"
                       @current-change="pageChange" :disabled="loading"></el-pagination>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="reset" text>关闭</el-button>
<!--            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>-->
        </template>
    </el-dialog>
    <Differences ref="differences"></Differences>
</template>

<script>
import axios from "ts-axios-new";
import {VueDraggableNext} from 'vue-draggable-next'
import Differences from "../Differences.vue";
import {ElMessageBox} from "element-plus";
import {has_feature_rookie_apply} from "../../libs/permission";

export default {
    name: "TranssionRookie",
    components: {Differences, VueDraggableNext},
    data() {
        return {
            loading: false, data: [], image_list: [], countries: [], series_list: [], total: 0, page: 1,
            // category_list: [], major_list: [], minor_list: [], assistant_list: [],
            // category_map: {}, major_map: {}, minor_map: {}, assistant_map: {}, source_list: [],
            dialog_opened: false, rookie: [], query: {id: '', module: 'LIBRARY', limit: 50, skip: 0, transsion_only: false},
            rookie_set: new Set(), labels: [0, 1],
            form: {
                day: 0,
                image_id: '',
                // country: '',
                label: 0,
            },
        }
    },
    methods: {
      has_feature_rookie_apply,
        init() {
            this.loading = true;
            axios.get(`/cms/v1/transsion/rookie`).then(res => {
                this.rookie = res.data.data.imageList;
            }).then(_ => {
                this.loading = false;
                this.formatData();
                this.refreshAdded();
            })
        },
        openDialog() {
            this.query.id = '';
            this.dialog_opened = true;
        },
        formatData() {
            this.data = [];
            this.countries = [];
            this.data_map = {}
            this.rookie_set = new Set()
            this.rookie.forEach(r => {
                if (!r.label) {
                  r.label = 0
                }
                this.rookie_set.add(r.label + "-" + r.image_id)
                let data = []
                let key = r.label
                if (this.data_map.hasOwnProperty(key)) {
                  data = this.data_map[key]
                } else {
                  this.data_map[key] = data
                }
                if (!data[r.day]) {
                  data[r.day] = {day: r.day, images: []}
                }
                this.image_list.forEach(i => {
                  if (i.id === r.image_id) {
                    r.thumbnail = i.thumbnail;
                  }
                })
                data[r.day].images.push(r);
            });
            this.changeData()
        },
        getImage(rookie) {
          const image = {}
          image.id = rookie.image_id
          image.differences = rookie.differences
          image.origin = rookie.origin
          return image
        },
        refreshAdded() {
          this.image_list.forEach(image => {
            image.added = this.rookie_set.has(this.form.label + "-" + image.id);
          });
        },
        reset() {
            if(this.$refs.form) {
              this.$refs.form.resetFields();
            }
            this.dialog_opened = this.loading = false;
            this.refreshAdded()
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    // if (!this.form.country) {
                    //     this.form.country = null;
                    // }
                    axios.post(`/cms/v1/transsion/rookie`, this.form).then(res => {
                        this.rookie.push(res.data.data);
                        this.init();
                        this.data.forEach(d => {
                            this.resort(d.images);
                        })
                        // this.reset();
                    });
                }
            })
        },
        remove(row) {
            this.loading = true;
            axios.delete(`/cms/v1/transsion/rookie/${row.id}`).then(_ => {
                this.init()
            });
        },
        addImage(image) {
            this.form.image_id = image.id;
            image.added = true
            this.submit()
        },
        // updateRookie(rookie, day) {
        //   // const country = this.form.country
        //   this.loading = true
        //   axios.put(`/cms/v1/transsion/rookie`, {image_id: rookie.image_id, day: day}).then(_ => {
        //     this.init();
        //   });
        // },
        updateRookie(row, refresh) {
          if(refresh) {
            this.loading = true;
          }
          axios.put(`/cms/v1/transsion/rookie`, {
            image_id: row.image_id, day: row.day,
            label: this.form.label}).then(_ => {
            if(refresh) {
              this.loading = false
              this.init();
            }
          })
        },
        applyRookie() {
          ElMessageBox.confirm(`确定要将所有的方案都应用成 "方案${this.form.label}" 吗？`, '提示', {
            cancelButtonText: '取消',
            confirmButtonText: '确定',
            type: 'warning',
          }).then(_ => {
            this.loading = true;
            axios.post(`/cms/v1/transsion/apply/rookie`, {label: this.form.label}).then(_ => {
              this.init();
              this.reset();
            });
          })
        },
        resort(images) {
            const sort = [];
            images.forEach(i => {
                sort.push(i.id);
            });
            this.loading = true;
            axios.post(`/cms/v1/transsion/sort/rookie`, {sort}).then(res => {
                this.loading = false;
            })
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
        reload() {
          if (!this.query.id || this.query.id.length === 24) {
            this.query.skip = 0;
            this.page = 1;
            this.loadImage()
          }
        },
        loadImage() {
            return axios.get(`/cms/v1/image`, {params: this.query}).then(res => {
              this.image_list = res.data.data.imageList;
              this.refreshAdded();
              this.total = res.data.data.total;
            })
        },
        pageChange(page) {
          this.query.skip = (page - 1) * this.query.limit;
          this.page = page;
          this.loadImage();
        },
        update(row) {
          this.loading = true;
          axios.put(`/cms/v1/transsion/image/${row.id}`, row).then(res => {
            this.loading = false;
          })
        },
        addCountry(country) {
            if (country && !this.countries.includes(country)) {
                this.countries.push(country);
                this.form.country = country;
                this.data = [];
            }
        },
        changeData() {
          this.data = [];
          let key = this.form.label
          if (this.data_map.hasOwnProperty(key)) {
            this.data = this.data_map[key]
          }
        },
    },
    computed: {
      rookieFilter() {
        return this.rookie.filter(d => {
          if (!d.label) {
            d.label = 0
          }
          return d.label === this.form.label;
        })
      },
    },
    mounted() {
        this.loading = true;
        axios.all([
            axios.get('/cms/v1/series').then(res => {
              this.series_list = res.data.data.seriesList;
            }),
           this.loadImage(),
        ]).then(_ => {
            this.init();
        })
    },
}
</script>

<style scoped>

.el-select {
  width: 200px;
}

</style>